<template>
  <div>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.1999 3.2001C3.1999 2.77575 3.36847 2.36878 3.66853 2.06873C3.96859 1.76867 4.37556 1.6001 4.7999 1.6001H11.1999C11.6242 1.6001 12.0312 1.76867 12.3313 2.06873C12.6313 2.36878 12.7999 2.77575 12.7999 3.2001V12.8001C13.0121 12.8001 13.2156 12.8844 13.3656 13.0344C13.5156 13.1844 13.5999 13.3879 13.5999 13.6001C13.5999 13.8123 13.5156 14.0158 13.3656 14.1658C13.2156 14.3158 13.0121 14.4001 12.7999 14.4001H10.3999C10.1877 14.4001 9.98425 14.3158 9.83422 14.1658C9.68419 14.0158 9.5999 13.8123 9.5999 13.6001V12.0001C9.5999 11.7879 9.51562 11.5844 9.36559 11.4344C9.21556 11.2844 9.01208 11.2001 8.7999 11.2001H7.1999C6.98773 11.2001 6.78425 11.2844 6.63422 11.4344C6.48419 11.5844 6.3999 11.7879 6.3999 12.0001V13.6001C6.3999 13.8123 6.31562 14.0158 6.16559 14.1658C6.01556 14.3158 5.81208 14.4001 5.5999 14.4001H3.1999C2.98773 14.4001 2.78425 14.3158 2.63422 14.1658C2.48419 14.0158 2.3999 13.8123 2.3999 13.6001C2.3999 13.3879 2.48419 13.1844 2.63422 13.0344C2.78425 12.8844 2.98773 12.8001 3.1999 12.8001V3.2001ZM5.5999 4.0001H7.1999V5.6001H5.5999V4.0001ZM7.1999 7.2001H5.5999V8.8001H7.1999V7.2001ZM8.7999 4.0001H10.3999V5.6001H8.7999V4.0001ZM10.3999 7.2001H8.7999V8.8001H10.3999V7.2001Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class Office extends Vue {}
</script>
